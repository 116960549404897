@import 'variables';

$sidebar: $sidebar-background;
$sidebar-alt: $sidebar-background;
$inverse: $widget-header;

@import "../../vendor/randock/adminpress-bundle/src/Resources/assets/js/bootstrap/css/bootstrap.min.css";
@import "./vendor/randock/adminpress-bundle/src/Resources/assets/scss/spinners.scss";
@import "./vendor/randock/adminpress-bundle/src/Resources/assets/scss/animate.scss";
@import "./vendor/randock/adminpress-bundle/src/Resources/assets/scss/material.scss";
@import "./vendor/randock/adminpress-bundle/src/Resources/assets/scss/style";