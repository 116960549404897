/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */
 
@import 'variable';
@import 'app';
@import 'pages';
@import 'sidebar';

@import 'icons/flag-icon-css/flag-icon.min';
@import "icons/font-awesome/scss/fontawesome";

@import 'widgets';
@import 'grid';
@import 'responsive';
@import 'material';
