/*
Template Name: Admin Press Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/


/*******************
Main sidebar
******************/

.left-sidebar {
    position: absolute;
    width: 240px;
    height: 100%;
    top: 0px;
    z-index: 20;
    padding-top: 60px;
    background: $sidebar;
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
}

.fix-sidebar .left-sidebar {
    position: fixed;
}



/*******************
use profile section
******************/

.user-profile {
    position: relative;
    background-size: cover;
    .setpos {
        top: -47px;
        right: -3px;
    }
    .profile-img {
        width: 70px;
        margin: 0 auto;
        padding: 10px 0 5px 0;
        border-radius: 100%;
        img {
            width: 100%;
            padding: 5px;
            border: 1px solid $border;
            border-radius: 100%;
        }
    }
    .profile-text {
        padding: 5px 0px;
        position: relative;
        text-align: center;
        >a {
            color: $muted;
            padding: 0 5px;
            &:hover {
                color: $themecolor;
            }
            &:after {
                display: none;
            }
        }
    }
    .dropdown-menu {
        left: 0px;
        right: 0px;
        top: 62px!important;
        width: 180px;
        margin: 0 auto;
    }
}



/*******************
sidebar footer
******************/

.sidebar-footer {
    position: fixed;
    z-index: 10;
    bottom: 0px;
    left: 0px;
    transition: 0.2s ease-out;
    width: 240px;
    background: $sidebar;
    border-top: 1px solid $border;
    a {
        padding: 15px;
        width: 33.333337%;
        float: left;
        text-align: center;
        font-size: 18px;
    }
}



/*******************
sidebar navigation
******************/

.scroll-sidebar {
    padding-bottom: 60px;
}

.collapse.in {
    display: block;
}

.sidebar-nav {
    background: $sidebar;
    padding: 0px;
    ul {
        margin: 0px;
        padding: 0px;
        li {
            list-style: none;
            a {
                color: $sidebar-text;
                padding: 8px 35px 8px 15px;
                display: block;
                font-size: 14px;
                &.active,
                &:hover {
                    color: $themecolor;
                    i {
                        color: $themecolor;
                    }
                }
                &.active {
                    font-weight: 500;
                    color: $dark;
                }
            }
            ul {
                padding-left: 28px;
                li a {
                    padding: 7px 35px 7px 15px;
                }
                ul {
                    padding-left: 15px;
                }
            }
            &.nav-small-cap {
                font-size: 12px;
                margin-bottom: 0px;
                padding: 14px 14px 14px 20px;
                color: $dark;
                font-weight: 500;
            }
            &.nav-devider {
                height: 1px;
                background: $border;
                display: block;
                margin: 20px 0;
            }
        }
    }
    >ul>li>a {
        border-left: 3px solid transparent;
        i {
            width: 27px;
            font-size: 19px;
            display: inline-block;
            vertical-align: middle;
            color: $sidebar-icons;
        }
        .label {
            float: right;
            margin-top: 6px;
        }
        &.active {
            font-weight: 400;
            background:#242933;
            color: $themecolor-alt;
        }
    }
    >ul>li {
        margin-bottom: 5px;
        &.active>a {
            color: $themecolor;
            font-weight: 500;
            border-left: 3px solid $themecolor;
            i {
                color: $themecolor;
            }
        }
    }
}

.sidebar-nav .has-arrow {
    position: relative;
    &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: $sidebar-text;
        right: 1em;
        -webkit-transform: rotate(135deg) translate(0, -50%);
        -ms-transform: rotate(135deg) translate(0, -50%);
        -o-transform: rotate(135deg) translate(0, -50%);
        transform: rotate(135deg) translate(0, -50%);
        -webkit-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        top: 47%;
        -webkit-transition: all .3s ease-out;
        -o-transition: all .3s ease-out;
        transition: all .3s ease-out;
    }
}

.sidebar-nav .active>.has-arrow::after,
.sidebar-nav li>.has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
    -webkit-transform: rotate(-135deg) translate(0, -50%);
    -ms-transform: rotate(-135deg) translate(0, -50%);
    -o-transform: rotate(-135deg) translate(0, -50%);
    top: 45%;
    width: 7px;
    transform: rotate(-135deg) translate(0, -50%);
}



/****************
When click on sidebar toggler and also for tablet
*****************/

@media(min-width:768px) {
    .mini-sidebar .sidebar-nav #sidebarnav li {
        position: relative;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li>ul {
        position: absolute;
        left: 60px;
        top: 45px;
        width: 200px;
        z-index: 1001;
        background: $sidebar-alt;
        display: none;
        padding-left: 1px;
    }
    .mini-sidebar .user-profile .profile-img {
        width: 50px;
        .setpos {
            top: -35px;
        }
    }
    .mini-sidebar.fix-sidebar .left-sidebar {
        position: absolute;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul {
        height: auto !important;
        overflow: auto;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul,
    .mini-sidebar .sidebar-nav #sidebarnav>li:hover>ul.collapse {
        display: block;
    }
    .mini-sidebar .sidebar-nav #sidebarnav>li>a.has-arrow:after {
        display: none;
    }
    .mini-sidebar {
        .left-sidebar {
            width: 60px;
        }
        .user-profile {
            padding-bottom: 15px;
            width: 60px;
            margin-bottom: 7px;
            .profile-img {
                padding: 15px 0 0 0;
                margin: 0px 0 0 6px;
                &:before {
                    top: 15px;
                }
            }
        }
        .scroll-sidebar {
            padding-bottom: 0px;
            position: absolute;
            overflow-x: hidden !important;
        }
        .hide-menu,
        .nav-small-cap,
        .sidebar-footer,
        .user-profile .profile-text,
        >.label {
            display: none;
        }
        .nav-devider {
            width: 60px;
        }
        .sidebar-nav {
            background: transparent;
        }
        .sidebar-nav #sidebarnav>li>a {
            padding: 9px 18px;
            width: 50px;
        }
        .sidebar-nav #sidebarnav>li:hover>a {
            width: 260px;
            background: $sidebar-alt;
            .hide-menu {
                display: inline;
            }
            .label {
                display: none;
            }
        }
    }
}

@media(max-width:767px) {
    .mini-sidebar {
        .left-sidebar {
            position: fixed;
        }
        .left-sidebar,
        .sidebar-footer {
            left: -240px;
        }
        &.show-sidebar {
            .left-sidebar,
            .sidebar-footer {
                left: 0px;
            }
        }
    }
}